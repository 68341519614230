<template>
  <priori-modal ref="addQuestionModal" modal-id="add-question-modal" title="Add Custom Section">
    <template #modal-trigger="{ openModal }">
      <a v-if="!isEditing" href="" @click.prevent="openModal">Add Custom Section</a>
      <button v-else type="button" class="pseudo-link-blue bold-weight d-flex gap-4 size-text-13px" @click="initForm(openModal)">Edit</button>
    </template>

    <validation-observer ref="validationObserver" v-slot="{ handleSubmit }" :disabled="true">
      <form role="form" class="a-form" novalidate>
        <div class="bottom-20">
          <text-input-vertical label="Enter the name of the section" id="rfq-question-text" input-name="rfqQuestionText" rules="required" v-model="questionForm.questionText"></text-input-vertical>
        </div>

        <div class="row tight-columns top-30">
          <div class="col-sm-5">
            <button v-if="!isEditing" type="button" class="primary-btn-blue" @click.prevent="handleSubmit(addSection)">Add Section</button>
            <button v-else type="button" class="primary-btn-blue" @click.prevent="handleSubmit(updateSection)">Add Section</button>
          </div>

          <div class="col-sm-4 top-20-xs">
            <button type="button" class="nv-button-white" @click.prevent="dismissModal">Cancel</button>
          </div>
        </div>
      </form>
    </validation-observer>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import { ValidationObserver } from 'vee-validate';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'RfqTemplateAddCustomSection',

  components: {
    PrioriModal,
    TextInputVertical,
    ValidationObserver
  },

  props: {
    group: {
      type: Object,
      default: null
    },

    isEditing: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      questionForm: {
        questionText: null
      }
    };
  },

  computed: {
    ...mapState(useRfqTemplateStore, {
      rfqTemplate: 'rfqTemplate',
      nextSectionPosition: 'nextSectionPosition'
    })
  },

  methods: {
    ...mapActions(useRfqTemplateStore, {
      addGroup: 'addGroup',
      updateGroup: 'updateGroup'
    }),

    initForm(openModal) {
      this.questionForm.questionText = this.group.name || null;
      openModal();
    },

    updateSection() {
      const updatedGroup = {
        ...this.group,
        name: this.questionForm.questionText
      };
      this.updateGroup(updatedGroup);
      this.dismissModal();
    },

    addSection() {
      const section = {
        handlesLawyers: false,
        name: this.questionForm.questionText,
        position: this.nextSectionPosition,
        questionTemplatesAttributes: []
      };
      this.addGroup(section);
      this.dismissModal();
    },

    dismissModal() {
      this.$refs.addQuestionModal.closeModal();
      this.clearForm();
    },

    clearForm() {
      this.questionForm.questionText = null;
      this.$refs.validationObserver.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
  svg {
    fill: #0069C7;

    &.sm-icon {
      width: 13px;
    }
  }
</style>
