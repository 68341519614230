<template>
  <div class="question-wrapper vertical-center">
    <div class="question-icon right-15 gap-10 d-flex">
      <svg-icon :name="findIcon" class="base-icon"></svg-icon>
    </div>

    <div class="width-100 right-15">
      <div class="bold-weight">
        {{ question.questionText }}
      </div>

      <div>
        <span class="sub-text">
          {{ findSubText }}
        </span>
      </div>
    </div>

    <div v-if="isEditable" class="d-flex gap-20 vertical-center">
      <rfq-question-form
        v-if="question.isCustomQuestion"
        :question="question"
        :group="group"
        :is-editing="true">
      </rfq-question-form>

      <a href="" @click.prevent="removeQuestion(question, group)" v-if="!ratesAndPricing(group) || (ratesAndPricing(group) && question.isCustomQuestion)">
        <svg-icon name="trash" class="base-icon blue-4-fill"></svg-icon>
      </a>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import RfqQuestionForm from 'vue-app/scout/client/settings/rfqs/templates/new/question-form.vue';
import { mapActions, mapState } from 'pinia';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
export default {
  name: 'RfqQuestionItem',

  components: {
    SvgIcon,
    RfqQuestionForm
  },

  props: {
    question: {
      type: Object,
      required: true
    },

    group: {
      type: Object,
      required: true
    },

    isEditable: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapState(useRfqTemplateStore, {
      rfqSelectedTemplate: 'rfqSelectedTemplate'
    }),

    findIcon() {
      switch (this.question.questionType) {
        case 'text':
          return 'form-text';
        case 'file_upload':
          return 'form-upload';
        case 'option':
          return 'form-radio-button';
        case 'range':
          return 'form-dollar';
        default:
          return 'form-text';
      }
    },

    findSubText() {
      switch (this.question.questionType) {
        case 'text':
          return 'Short answer text';
        case 'file_upload':
          return 'File Upload';
        case 'option':
          return 'Radio buttons + short answer text';
        case 'range':
          return 'Dollar amount(s)';
        default:
          return '';
      }
    }
  },

  methods: {
    ...mapActions(useRfqTemplateStore, {
      removeQuestion: 'removeQuestion'
    }),

    ratesAndPricing(group) {
      return group.position === 2;
    },

    checkedChanged() {
      this.question.isIncluded = !this.question.isIncluded;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";
  .question-wrapper {
    border-radius: $border-radius-large;
    padding: 10px 15px;
    border: 1px solid $k-gray;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .sub-text {
    color: $pill-gray;
    border-bottom: 1px dotted $pill-gray;
    font-size: 12px;
  }
</style>
