<template>
  <div>
    <div class="titlebar">
      <div class="medium-page-heading">
        Admin Settings
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row">
            <div class="col-sm-8">
              <div class="bottom-30">
                <users-summary :workspace="workspace"></users-summary>
              </div>

              <div>
                <scout-tag-editing-panel
                  :scout-tags="scoutTags"
                  :on-order-updated="reorderScoutTags"
                  :on-create-tag="createTag"
                  :on-update-tag="updateTag"
                  :on-delete-tag="deleteTag">
                </scout-tag-editing-panel>
              </div>

              <div class="top-30" v-if="!isFeatureLab">
                <rfp-settings
                  :workspace="workspace"
                  :update-workspace="updateWorkspace">
                </rfp-settings>
              </div>

              <div class="top-30">
                <rfq-templates
                  :template-count="templateCount">
                </rfq-templates>
              </div>

              <div class="top-30" v-if="!isFeatureLab">
                <marketplace-search-settings :allow-marketplace="workspace.allowMarketplace"></marketplace-search-settings>
              </div>

              <div class="top-30" v-if="!isFeatureLab">
                <review-reminder-email-settings
                  :allow-emails="workspace.hasReviewReminderEmailsEnabled"
                  :allow-disable-emails="workspace.canDisableReviewReminderEmails">
                </review-reminder-email-settings>
              </div>

              <div class="top-30" v-if="!isFeatureLab">
                <search-filters
                  :lawyer-filters="lawyerFilters"
                  :firm-filters="firmFilters">
                </search-filters>
              </div>
            </div>

            <div class="col-sm-4 top-30-xs" v-if="!isFeatureLab">
              <e-billing-upload></e-billing-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderBy }                 from 'lodash';
import { mapActions }              from 'pinia';
import EBillingUpload              from 'vue-app/scout/client/settings/e-billing-upload.vue';
import LoadingService              from 'vue-app/shared/services/loading-service';
import MarketplaceSearchSettings   from 'vue-app/scout/client/settings/marketplace-search-settings.vue';
import ReviewReminderEmailSettings from 'vue-app/scout/client/settings/review-reminder-email-settings.vue';
import RfpSettings                 from 'vue-app/scout/client/settings/rfp-settings.vue';
import ScoutTagEditingPanel        from 'vue-app/scout/client/settings/scout-tag-editing-panel.vue';
import SearchFilters               from 'vue-app/scout/client/settings/search-filters.vue';
import UsersSummary                from 'vue-app/scout/client/settings/users-summary.vue';
import RfqTemplates                from 'vue-app/scout/client/settings/rfq-templates.vue';
import ScoutWorkspace from 'resources/scout/scout-workspace.js';
import ScoutTag from 'vue-app/scout/resources/scout-tag.js';
import ScoutSearch from 'resources/scout/scout-search.js';
import NotificationService from 'vue-app/shared/services/notification-service.js';
import useCurrentUserStore  from 'vue-app/stores/current-user.js';

export default {
  name: 'ScoutClientSettingsIndex',

  components: {
    EBillingUpload,
    MarketplaceSearchSettings,
    ReviewReminderEmailSettings,
    RfpSettings,
    ScoutTagEditingPanel,
    SearchFilters,
    UsersSummary,
    RfqTemplates
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      firmFilters: this.initData.firmFilters,
      lawyerFilters: this.initData.lawyerFilters,
      workspace: this.initData.workspace,
      scoutTags: this.initData.scoutTags,
      templates: this.initData.templates,
      // Refresh the sidenav. Since that's still in Angular, I'm passing in a
      // function in initData. When fully on Vue, we can do it directly.
      refreshSavedSearches: this.initData.refreshSavedSearches
    };
  },

  computed: {
    isFeatureLab() {
      return this.workspace.isFeatureLab;
    },

    templateCount() {
      return this.workspace.templateCount;
    }
  },

  methods: {
    ...mapActions(useCurrentUserStore, { refetchCurrentUser: 'refetchCurrentUser' }),

    loadScoutTags() {
      LoadingService.loading('scoutTags');

      return ScoutTag.query().then((scoutTags) => {
        this.scoutTags = orderBy(scoutTags, 'position');
        LoadingService.done('scoutTags');
      });
    },

    reorderScoutTags(scoutTags) {
      return ScoutTag.order({ order: scoutTags }).then((scoutTags) => {
        this.scoutTags = scoutTags;
      });
    },

    createTag(tag, createSavedSearch) {
      return ScoutTag.save({ scoutTag: tag }).then((response) => {
        this.loadScoutTags();
        NotificationService.success('Law firm tag successfully saved.');

        if (createSavedSearch) { this.createSavedSearch(response); }
      });
    },

    updateTag(tag) {
      return ScoutTag.update({ id: tag.id, scoutTag: tag }).then(() => {
        this.loadScoutTags();
        this.refreshSavedSearches();
        NotificationService.success('Law firm tag successfully updated.');
      });
    },

    deleteTag(tag) {
      return ScoutTag.delete({ id: tag.id })
        .then(() => {
          this.loadScoutTags();
          this.refreshSavedSearches();
        })
        .catch((error) => {
          NotificationService.error(`Unable to save changes: ${error}`);
        });
    },

    updateWorkspace(workspace) {
      LoadingService.loading('workspace');
      return ScoutWorkspace.update({ id: workspace.id, workspace: workspace, view: 'settings' }).then(workspace => {
        this.workspace = workspace;
        this.refetchCurrentUser();
        LoadingService.done('workspace');
      });
    },

    createSavedSearch(tag) {
      const params = {
        name: tag.name,
        searchParams: {
          type: 'law_firms',
          filters: { law_firm_tags: [tag.name] },
          logic: {},
          queries: {},
          sort_case_insensitive: {}
        },
        scoutTagId: tag.id
      };

      return ScoutSearch.save({ scoutSearch: params }).then(() => {
        this.refreshSavedSearches();
      });
    }
  }
};
</script>
