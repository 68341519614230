<template>
  <div>
    <dropdown-menu v-if="!isEditing" :compact="true" :align-right="false">
      <template #trigger>
        <div>
          <button data-role="trigger" type="button" class="pseudo-link-blue bold-weight d-flex items-center gap-5">Add Question <svg-icon name="caret-down" class="caret-down sm-icon"></svg-icon></button>
        </div>
      </template>

      <li class="menu-item" role="menuitem" v-if="hiddenQuestionsForGroup(group).length > 0">
        <rfq-template-add-standard-question :is-editing="isEditing" :group="group" />
      </li>

      <li class="menu-item" role="menuitem">
        <rfq-template-add-custom-question :is-editing="isEditing" :group="group" :question="question" />
      </li>
    </dropdown-menu>
    <rfq-template-add-custom-question v-else :group="group" :is-editing="isEditing" :question="question" />
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import RfqTemplateAddCustomQuestion from 'vue-app/scout/client/settings/rfqs/templates/new/add-custom-question.vue';
import RfqTemplateAddStandardQuestion from 'vue-app/scout/client/settings/rfqs/templates/new/add-standard-question.vue';
import { mapState } from 'pinia';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';

export default {
  name: 'RfqTemplateQuestionForm',

  components: {
    SvgIcon,
    DropdownMenu,
    RfqTemplateAddCustomQuestion,
    RfqTemplateAddStandardQuestion
  },

  props: {
    group: {
      type: Object,
      default: null
    },

    question: {
      type: Object,
      default: null
    },

    isEditing: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(useRfqTemplateStore, {
      hiddenQuestionsForGroup: 'hiddenQuestionsForGroup'
    })
  }
};
</script>

<style lang="scss" scoped>
  svg {
    fill: #0069C7;

    &.sm-icon {
      width: 13px;
    }
  }
</style>
