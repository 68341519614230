<template>
  <priori-modal ref="addQuestionModal" :title="formTitle" @on-modal-close="clearForm">
    <template #modal-trigger="{ openModal }">
      <a v-if="!isEditing" href="" @click.prevent="openModal">Add Custom Question</a>
      <button v-else type="button" class="pseudo-link-blue bold-weight d-flex gap-4 size-text-13px" @click="initForm(openModal)"><svg-icon name="pencil" class="blue-4-fill w-20px"></svg-icon></button>
    </template>

    <validation-observer ref="observer" v-slot="{ handleSubmit }" :disabled="true">
      <form role="form" class="a-form" novalidate>
        <div class="bottom-30">
          <text-input-vertical label="Enter your question" id="rfq-question-text" input-name="rfqQuestionText" rules="required" v-model="questionForm.questionText"></text-input-vertical>
        </div>

        <validation-provider ref="typeValidator" rules="required" v-slot="{ errors }" :mode="passiveAggressive">
          <dropdown-select
            ref="typeDropdown"
            :required="true"
            label="Select answer type"
            placeholder="Please Select"
            id-label="rfq-question-type"
            :initial-label="initialLabel"
            :options="typeOptions"
            value-key="code"
            label-key="text"
            :invalid="errors.length > 0"
            v-model="questionForm.questionType">
          </dropdown-select>

          <div class="error-text top-5" v-if="errors.length">
            {{ errors[0] }}
          </div>
        </validation-provider>

        <div v-if="!isEditing" class="row tight-columns top-30">
          <div class="col-sm-5">
            <button type="button" class="primary-btn-blue" @click="handleSubmit(confirmAddQuestion)">Add Question</button>
          </div>

          <div class="col-sm-3 top-10-xs">
            <button type="button" class="nv-button-white" @click="dismissModal">Cancel</button>
          </div>
        </div>

        <div v-else class="row tight-columns vertical-center-not-xs top-30">
          <div class="col-sm-4">
            <button type="button" class="primary-btn-blue" @click="handleSubmit(confirmUpdateQuestion)">Save</button>
          </div>

          <div class="col-sm-3 top-20-xs">
            <button type="button" class="nv-button-white" @click="dismissModal">Cancel</button>
          </div>
        </div>
      </form>
    </validation-observer>
  </priori-modal>
</template>
<script>

import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapActions } from 'pinia';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';

export default {
  name: 'RfqTemplateAddCustomQuestion',

  components: {
    PrioriModal,
    SvgIcon,
    TextInputVertical,
    DropdownSelect,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [
    interactionModes
  ],

  props: {
    group: {
      type: Object,
      default: null
    },

    question: {
      type: Object,
      default: null
    },

    isEditing: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      questionForm: {
        questionType: this.question?.questionType || '',
        questionText: this.question?.questionText || null
      },
      typeOptions: [
        { code: 'text', text: 'Short Answer Text' },
        { code: 'range', text: 'Dollar Amount' }
      ]
    };
  },

  computed: {
    formTitle() {
      return this.isEditing ? 'Edit Custom Question' : 'Add Custom Question';
    },

    initialLabel() {
      switch (this.questionForm.questionType) {
        case 'text':
          return 'Short Answer Text';
        case 'range':
          return 'Dollar Amount';
        default:
          return '';
      }
    }
  },

  methods: {
    ...mapActions(useRfqTemplateStore, {
      addQuestion: 'addQuestion',
      updateQuestion: 'updateQuestion'
    }),

    initForm(openModal) {
      this.questionForm.questionType = this.question?.questionType || '';
      this.questionForm.questionText = this.question?.questionText || null;
      openModal();
    },

    confirmAddQuestion() {
      const question = {
        questionText: this.questionForm.questionText,
        questionType: this.questionForm.questionType,
        isCustomQuestion: true,
        position: this.group.questionTemplatesAttributes.length + 1,
        isIncluded: true,
        isRequired: true,
        prerequisiteQuestionAnswer: null,
        prerequisiteQuestionId: null,
        questionOptions: null,
        scoutRfqQuestionGroupTemplateId: null,
        shortname: ''
      };
      this.addQuestion(question, this.group);
      this.dismissModal();
    },

    confirmUpdateQuestion() {
      const updatedQuestion = {
        ...this.question,
        questionText: this.questionForm.questionText,
        questionType: this.questionForm.questionType
      };
      this.updateQuestion(updatedQuestion, this.group);
      this.dismissModal();
    },

    dismissModal() {
      this.$refs.addQuestionModal.closeModal();
      this.clearForm();
    },

    clearForm() {
      this.questionForm.questionType = '';
      this.questionForm.questionText = null;
      this.$refs.observer.reset();
      this.$refs.typeDropdown.reset();
    }
  }
};
</script>
