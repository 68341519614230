<template>
  <div class="shadowed-box">
    <div v-if="showTemplateHeading" class="box-header">
      <div class="row tight-columns">
        <div class="col-sm-10 text-uppercase">
          Template Questions
        </div>

        <div class="col-sm-2 text-right-not-xs">
          <div v-if="!isNew && !isStandardTemplate">
            <div v-if="isEditing" class="d-flex flex-end gap-20">
              <div>
                <button type="button" class="pseudo-link-blue" @click="saveEdit">Save</button>
              </div>
              <div>
                <button type="button" class="pseudo-link semibold-weight" @click="cancelEdit">Cancel</button>
              </div>
            </div>

            <button v-else type="button" class="pseudo-link-blue" @click="startEdit">Edit Questions</button>
          </div>
        </div>
      </div>
    </div>

    <div class="box-content">
      <div class="list-spacing-30" v-for="group in orderedQuestionGroups" :key="group.id">
        <div class="size-text-15px bold-weight bottom-10 row tight-columns">
          <div class="col-sm-10 d-flex">
            {{ group.name }}
            <div v-if="isRatesAndPricing(group)" class="vertical-center">
              <span class="info-icon left-5 vertical-center">
                <svg-icon id="rates-and-pricing-tooltip" name="info" class="base-icon smaller text-top"></svg-icon>
              </span>
              <tooltip
                target="#rates-and-pricing-tooltip"
                placement="top"
                custom-class="tooltip-white small-text"
                text="These questions power the rate comparison and suggested lawyer features in RFPs and cannot be removed from templates.">
              </tooltip>
            </div>
          </div>
          <div class="col-sm-2 flex-end-justified vertical-center">
            <rfq-template-group-form v-if="isCustomGroup(group) && isEditable" class="text-right" :group="group" :is-editing="true"></rfq-template-group-form>
          </div>
        </div>

        <div v-if="hasQuestions(group)">
          <ordered-question-group :group="group" :is-editing="isEditing" :is-editable="isEditable"></ordered-question-group>
        </div>

        <div v-else class="padded-container text-center empty-list bottom-30">
          You have not added any questions. <span @click="removeSection(group)" class="pseudo-link-blue pill-gray-text semibold-weight">Remove Section</span>
        </div>

        <rfq-template-question-form
          v-if="isEditable"
          class="top-10"
          :group="group"
          :is-editing="false">
        </rfq-template-question-form>
      </div>

      <rfq-template-group-form v-if="isEditable" class="top-10"></rfq-template-group-form>

      <div v-if="isEditingExistingTemplate">
        <div class="row tight-columns top-30">
          <div class="col-sm-2">
            <button type="button" class="primary-btn-blue" @click="saveEdit">Save</button>
          </div>

          <div class="col-sm-2 top-20-xs">
            <button type="button" class="nv-button-white" @click="cancelEdit">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfqTemplateQuestionForm from 'vue-app/scout/client/settings/rfqs/templates/new/question-form.vue';
import RfqTemplateGroupForm from 'vue-app/scout/client/settings/rfqs/templates/new/group-form.vue';
import { mapActions, mapState } from 'pinia';
import { orderBy } from 'lodash';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import LoadingService from 'vue-app/shared/services/loading-service';
import { scrollToTop } from 'misc/ui-helpers.js';
import NotificationService from 'vue-app/shared/services/notification-service';
import OrderedQuestionGroup from 'vue-app/scout/client/settings/rfqs/templates/ordered-question-group.vue';

export default {
  name: 'RfqTemplateQuestions',

  components: {
    RfqTemplateQuestionForm,
    RfqTemplateGroupForm,
    OrderedQuestionGroup
  },

  props: {
    isNew: {
      type: Boolean,
      default: true
    },

    isEditing: {
      type: Boolean,
      default: false
    },

    showTemplateHeading: {
      type: Boolean,
      default: true
    },

    template: {
      type: Object,
      default: function () { return {}; }
    },

    questionGroups: {
      type: Array,
      default: () => []
    }
  },

  data: function () {
    return {
      persistedRfqTemplate: null,
      maxStandardQuestionsPosition: 4,
      ratesAndPricingPosition: 2,
      localQuestionGroups: [...this.questionGroups]
    };
  },

  computed: {
    ...mapState(useRfqTemplateStore, {
      rfqTemplate: 'rfqTemplate'
    }),
    orderedQuestionGroups() {
      if (this.isEditing) {
        if (this.localQuestionGroups && this.localQuestionGroups.length !== 0) {
          return this.parentQuestionGroups;
        }
        else {
          return this.editableQuestionGroups;
        }
      }
      else {
        return this.persistedQuestionGroups;
      }
    },
    isEditable() {
      return this.isNew || this.isEditing;
    },

    isEditingExistingTemplate() {
      return !this.isNew && this.isEditing;
    },

    isStandardTemplate() {
      return this.rfqTemplate.id === null && this.rfqTemplate.name === 'Standard Questions';
    },

    // for the case that we pass in question groups from the parent
    parentQuestionGroups() {
      return orderBy(
        this.localQuestionGroups,
        'position'
      );
    },

    editableQuestionGroups() {
      return orderBy(
        this.rfqTemplate.questionGroupTemplatesAttributes.filter(group => group.isShown === true || group.position > this.maxStandardQuestionsPosition),
        'position'
      );
    },

    persistedQuestionGroups() {
      return orderBy(
        this.template.questionGroupTemplatesAttributes.filter(group => group.isShown === true || group.position > this.maxStandardQuestionsPosition),
        'position'
      );
    }
  },
  watch: {
    questionGroups(newValue) {
      this.localQuestionGroups = newValue;
    }
  },
  methods: {
    ...mapActions(useRfqTemplateStore, {
      removeGroup: 'removeGroup',
      updateGroup: 'updateGroup',
      setRfqTemplate: 'setRfqTemplate',
      updateTemplate: 'updateTemplate'
    }),
    isCustomGroup(group) {
      return group.position > this.maxStandardQuestionsPosition;
    },

    hasQuestions(group) {
      return group.questionTemplatesAttributes.filter(q => !q._destroy).length > 0;
    },

    isRatesAndPricing(group) {
      return group.position === this.ratesAndPricingPosition;
    },

    removeSection(group) {
      this.isStandardTemplateGroup(group) ? this.hideStandardTemplateGroup(group) : this.removeGroup(group);
    },

    hideStandardTemplateGroup(group) {
      const updatedGroup = {
        ...group,
        isShown: false
      };
      this.updateGroup(updatedGroup);
    },

    isStandardTemplateGroup(group) {
      return group.position <= this.maxStandardQuestionsPosition;
    },

    startEdit() {
      this.persistedRfqTemplate = structuredClone(this.template);
      this.isEditing = true;
    },

    saveEdit() {
      LoadingService.loading('templates-show');
      this.updateTemplate(this.rfqTemplate)
        .then(() => {
          this.isEditing = false;
          NotificationService.success('This template has been successfully saved.');
        })
        .catch(() => {
          NotificationService.error('Failed to update template. Please try again.');
        })
        .finally(() => {
          scrollToTop();
          LoadingService.done('templates-show');
        });
    },

    cancelEdit() {
      this.setRfqTemplate(this.persistedRfqTemplate);
      this.isEditing = false;
    }
  }
};
</script>
<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .empty-list {
    border-radius: $border-radius-large;
    background-color: $k-lighter-gray;
    color: $pill-gray;
  }

  .blue-accent {
    background: $blue-10;
    padding: 10px 20px 20px 20px;
    border-radius: 6px;
  }
</style>
