<template>
  <priori-modal ref="addSectionModal" modal-id="add-section-modal" title="Add Standard Section">
    <template #modal-trigger="{ openModal }">
      <a href="" @click.prevent="openModal">Add Standard Section</a>
    </template>
    <div class="d-flex flex-column gap-10">
      <checkbox-item
        v-for="hiddenGroup in hiddenGroups" :key="hiddenGroup.position"
        :item="hiddenGroup"
        :is-checked="hiddenGroup.isShown"
        @input="(isChecked) => updateIsShown(hiddenGroup, isChecked)"
        :header-text="hiddenGroup.name"
        :sub-text="subTextForGroup(hiddenGroup)">
      </checkbox-item>
    </div>
    <div class="row tight-columns top-30">
      <div class="col-sm-5">
        <button type="button" class="primary-btn-blue" @click.prevent="addSections">Add Section(s)</button>
      </div>

      <div class="col-sm-4 top-20-xs">
        <button type="button" class="nv-button-white" @click.prevent="dismissModal">Cancel</button>
      </div>
    </div>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import { mapState, mapActions } from 'pinia';
import CheckboxItem from 'vue-app/scout/client/settings/rfqs/templates/new/checkbox-item.vue';
export default {
  name: 'RfqTemplateAddStandardSection',
  components: {
    PrioriModal,
    CheckboxItem
  },
  props: {
    group: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState(useRfqTemplateStore, {
      hiddenGroups: 'hiddenGroups',
      rfqTemplate: 'rfqTemplate'
    })
  },
  methods: {
    ...mapActions(useRfqTemplateStore, {
      updateGroup: 'updateGroup'
    }),

    /**
     * currentlyPersistedGroup needs to update both isShown and questionTemplatesAttributes.
     * Each group we set the existing persisted questions to _destroy true
     * so that the server can remove them, in place of the standard questions for the group
     * which is stored in (group.questionTemplatesAttributes)
     */
    addSections() {
      this.hiddenGroups.forEach((group) => {
        if (group.isShown) {
          const currentlyPersistedGroup = this.rfqTemplate.questionGroupTemplatesAttributes.find(rfqGroup => rfqGroup.position === group.position);

          const updatedGroup = {
            ...currentlyPersistedGroup,
            isShown: true,
            questionTemplatesAttributes: [
              ...currentlyPersistedGroup.questionTemplatesAttributes.map((question) => ({
                ...question,
                _destroy: true
              })),
              ...group.questionTemplatesAttributes
            ]
          };
          this.updateGroup(updatedGroup);
        }
      });
      this.dismissModal();
    },

    dismissModal() {
      this.hiddenGroups.forEach((group) => {
        group.isShown = false;
      });
      this.$refs.addSectionModal.closeModal();
    },

    updateIsShown(hiddenGroup, isChecked) {
      hiddenGroup.isShown = isChecked;
    },

    subTextForGroup(group) {
      return `${group.questionTemplatesAttributes.length} standard questions`;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";
  svg {
    fill: #0069C7;
    &.sm-icon {
      width: 13px;
    }
  }
</style>
