<template>
  <priori-modal ref="addQuestionModal" modal-id="add-question-modal" title="Add Standard Question">
    <template #modal-trigger="{ openModal }">
      <a href="" @click.prevent="openModal">Add Standard Question</a>
    </template>
    <div class="d-flex flex-column gap-10">
      <checkbox-item
        v-for="question in hiddenQuestionsForGroup(group)" :key="question.position"
        :item="question"
        :ref="`checkboxItem${question.position}`"
        @input="(isChecked) => updateIsShown(question, isChecked)"
        :header-text="question.questionText"
        :sub-text="findSubText(question)">
      </checkbox-item>
    </div>
    <div class="row tight-columns top-30">
      <div class="col-sm-5">
        <button type="button" class="primary-btn-blue" @click.prevent="addQuestions">Add Question(s)</button>
      </div>

      <div class="col-sm-3 top-10-xs">
        <button type="button" class="nv-button-white" @click.prevent="dismissModal">Cancel</button>
      </div>
    </div>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import { mapActions } from 'pinia';
import CheckboxItem from 'vue-app/scout/client/settings/rfqs/templates/new/checkbox-item.vue';
export default {
  name: 'RfqTemplateAddStandardQuestion',

  components: {
    PrioriModal,
    CheckboxItem
  },

  props: {
    group: {
      type: Object,
      default: null
    }
  },

  methods: {
    ...mapActions(useRfqTemplateStore, {
      hiddenQuestionsForGroup: 'hiddenQuestionsForGroup',
      addQuestion: 'addQuestion'
    }),

    addQuestions() {
      this.hiddenQuestionsForGroup(this.group).forEach((q) => {
        if (q.isShown) {
          const question = {
            ...q,
            position: this.group.questionTemplatesAttributes.length + 1
          };
          this.addQuestion(question, this.group);
        }
      });
      this.dismissModal();
    },

    updateIsShown(hiddenQuestion, isChecked) {
      hiddenQuestion.isShown = isChecked;
    },

    findSubText(question) {
      switch (question.questionType) {
        case 'text':
          return 'Short answer text';
        case 'file_upload':
          return 'File Upload';
        case 'option':
          return 'Radio buttons + short answer text';
        case 'range':
          return 'Dollar amount(s)';
        default:
          return '';
      }
    },

    dismissModal() {
      const hiddenQuestions = this.hiddenQuestionsForGroup(this.group);
      for (let i = 0; i < hiddenQuestions.length; i++) {
        const q = hiddenQuestions[i];
        const refName = `checkboxItem${q.position}`;
        if (this.$refs[refName]) {
          this.$refs[refName][0].reset();
        }
      }
      this.hiddenQuestionsForGroup(this.group).forEach((q) => q.isShown = false);
      this.$refs.addQuestionModal.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";
  svg {
    fill: #0069C7;
    &.sm-icon {
      width: 13px;
    }
  }
</style>
