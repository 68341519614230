<template>
  <validation-observer ref="observerRef" v-slot="{ handleSubmit }">
    <form role="form" name="form" class="a-form" novalidate>
      <div class="titlebar with-button">
        <div class="row">
          <div class="col-sm-7">
            <div class="medium-page-heading top-6">
              Create New Template
            </div>
          </div>

          <div class="col-sm-5 top-20-xs">
            <div class="row tight-columns">
              <div class="col-sm-7">
                <button type="button" class="primary-btn-blue" @click="handleSubmit(confirmSaveTemplate)">Save Template</button>
              </div>

              <div class="col-sm-5 top-10-xs">
                <a href="/scout-company/settings/question-templates" class="nv-button-white">Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sidenav-content">
        <div class="container-fluid">
          <div class="padded-container">
            <div class="shadowed-box bottom-30">
              <div class="box-header text-uppercase">
                Template Options
              </div>

              <div class="box-content">
                <div class="bottom-30">
                  <text-input-vertical
                    label="Template Name"
                    :name="'templateName'"
                    :id="'template-id'"
                    rules="required"
                    type="text"
                    v-model="rfqTemplate.name">
                  </text-input-vertical>
                  <div class="error-text top-5" v-if="hasResponseErrors">
                    <div class="size-text-12px">
                      <span v-if="hasResponseErrors">{{ responseErrorsDisplay }}</span>
                    </div>
                  </div>
                </div>

                <div class="bottom-20">
                  <radio-toggle
                    label="How would you like to customize your questions?"
                    name="customizationType"
                    :options="customizeQuestionOptions">
                    <template #section-one>
                      <div class="blue-accent">
                        <label for="k-toggle-button-selected-template">
                          Select a Template
                        </label>

                        <dropdown-select
                          ref="templateDropdown"
                          id-label="selected-template"
                          placeholder="Select One"
                          v-model="currentTemplate"
                          @change="updateSelectedTemplate"
                          value-key="name"
                          label-key="name"
                          :initial-label="rfqSelectedTemplate.name"
                          :options="rfqTemplates.map(obj => ({ name: obj.name, id: obj.id }))">
                        </dropdown-select>
                      </div>
                    </template>

                    <template #section-two>
                      <div class="blue-accent">
                        <label for="search-rfp">Search for an RFP<span class="inline-help required"> *</span></label>

                        <!-- TODO: this doesn't do anything -->
                        <div class="keyword-search-container">
                          <input type="text" name="searchRfp" id="search-rfp" class="form-control keyword-search-input" placeholder="Search by RFP Ref Name or #" autocomplete="off" v-model="templateRfq">
                          <svg-icon name="search" class="base-icon keyword-search-icon"></svg-icon>
                        </div>
                      </div>
                    </template>
                  </radio-toggle>
                </div>
              </div>
            </div>

            <rfq-template-questions :template="rfqTemplate"></rfq-template-questions>

            <div class="row tight-columns top-30">
              <div class="col-sm-3">
                <button type="button" class="primary-btn-blue" @click="handleSubmit(confirmSaveTemplate)">Save Template</button>
              </div>

              <div class="col-sm-2 top-10-xs">
                <a href="/scout-company/settings/question-templates" class="nv-button-white">Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState, mapActions } from 'pinia';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import { ValidationObserver } from 'vee-validate';
import RadioToggle from 'vue-app/shared/components/radio-toggle.vue';
import RfqTemplateQuestions from 'vue-app/scout/client/settings/rfqs/templates/questions.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import NotificationService from 'vue-app/shared/services/notification-service';

export default {
  name: 'ScoutClientSettingsRfpTemplatesNew',

  components: {
    ValidationObserver,
    RadioToggle,
    RfqTemplateQuestions,
    TextInputVertical
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      templates: this.initData.templates,
      currentTemplate: this.rfqSelectedTemplate,
      customizeQuestionOptions: [
        { text: 'Start from a template', value: 'template' },
        { text: 'Start from a recently sent RFP', value: 'recently-sent' }
      ],
      responseErrors: []
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),
    ...mapState(useRfqTemplateStore, {
      rfqTemplate: 'rfqTemplate',
      rfqTemplates: 'rfqTemplates',
      rfqSelectedTemplate: 'rfqSelectedTemplate'
    }),

    hasResponseErrors() {
      return this.responseErrors.length > 0;
    },

    responseErrorsDisplay() {
      return this.responseErrors.join('; ');
    }
  },

  mounted() {
    this.setRfqGroupTemplates(structuredClone(this.templates[0].questionGroupTemplatesAttributes));
    this.setTemplates(structuredClone(this.templates));
  },

  methods: {
    ...mapActions(useRfqTemplateStore, {
      setRfqGroupTemplates: 'setRfqGroupTemplates',
      setTemplates: 'setTemplates',
      saveTemplate: 'saveTemplate',
      removeGroup: 'removeGroup',
      setSelectedTemplateByName: 'setSelectedTemplateByName'
    }),

    confirmSaveTemplate() {
      this.responseErrors = [];
      this.saveTemplate()
        .then((response) => {
          NotificationService.success('This template has been successfully created.', true);
          window.location.href = `/scout-company/settings/question-templates/${response.id}`;
        })
        .catch((response) => {
          this.responseErrors = (response?.response?.data?.errors || []);
        });
    },

    // Use name b/c it's unique to a workspace.
    updateSelectedTemplate(templateName) {
      this.setRfqGroupTemplates(structuredClone(this.templates.find((t) => t.name === templateName).questionGroupTemplatesAttributes));
      this.setSelectedTemplateByName(templateName);
    }
  }
};
</script>
<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .blue-accent {
    background: $blue-10;
    padding: 10px 20px 20px 20px;
    border-radius: 6px;
  }
</style>
