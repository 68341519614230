<template>
  <div>
    <div class="shadowed-box">
      <div class="box-content extra-padded">
        <div class="size-text-17px bold-weight bottom-5">
          Choose Questions for Recipients
        </div>

        <div class="bottom-30">
          Select a template to see and edit the questions you would like RFP recipients to answer regarding your project.
        </div>
        <div class="blue-accent">
          <label for="templateDropdown">Select a Template</label>
          <div class="template-link text-right">
            <a @click="manageTemplates" href="#">Manage Templates</a>
          </div>
          <dropdown-select
            ref="templateDropdown"
            id-label="selected-template"
            placeholder="Please Select"
            v-model="currentTemplate"
            @change="onTemplateChange"
            value-key="name"
            label-key="name"
            :initial-label="existingQuestionGroups.length > 0 ? 'Standard Questions (Default)' : 'Please Select'"
            :options="rfqTemplates.map(obj => ({ name: obj.name, id: obj.id }))">
          </dropdown-select>
          <div v-if="false || existingQuestionGroups.length > 0" class="text-left">
            <em>Template edited for this RFP</em>
          </div>
        </div>
        <rfq-template-questions
          v-if="currentTemplate != '' || existingQuestionGroups.length > 0"
          :is-editing="true"
          :question-groups="existingQuestionGroups"
          :show-template-heading="false"
          @update-template-questions="handleQuestionUpdate">
        </rfq-template-questions>
        <div class="row top-30">
          <div class="col-sm-4 col-md-3">
            <button type="button" class="secondary-btn-blue" @click="previous">Previous</button>
          </div>

          <div class="col-sm-4 col-sm-offset-4 col-md-3 col-md-offset-6 top-20-xs">
            <button type="button" class="primary-btn-blue" :disabled="!formIsValid()" @click="next">Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import RFQTemplate from 'resources/scout/rfq-template.js';
import useRfqTemplateStore from 'vue-app/stores/rfq-template.js';
import RfqTemplateQuestions from 'vue-app/scout/client/settings/rfqs/templates/questions.vue';

export default {
  name: 'RfqStepQuestions',

  components: {
    RfqTemplateQuestions
  },
  props: {
    nextStep: {
      type: Object
    },
    prevStep: {
      type: Object
    },
    rfq: {
      type: Object,
      required: true
    },
    onSave: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      currentTemplate: '',
      showSwitchTemplateModal: false,
      unsavedChanges: false,  // TODO: Get a modal warning when unsaved changes
      nextTemplateName: '',
      existingQuestionGroups: [], // to be populated if one exists on the RFQ already
      rfqQuestionGroups: [] // for the local state of question groups
    };
  },
  computed: {
    ...mapState(useRfqTemplateStore, ['rfqTemplates', 'rfqSelectedTemplate'])
  },
  mounted() {
    this.initializeQuestionGroups();
    RFQTemplate.query({ view: 'show' })
      .then((templates) => {
        this.setTemplates(structuredClone(templates));
      });
  },
  methods: {
    ...mapActions(useRfqTemplateStore, [
      'setTemplates',
      'setRfqGroupTemplates',
      'setSelectedTemplateByName'
    ]),
    initializeQuestionGroups() {
      if (this.rfq.questionGroups && this.rfq.questionGroups.length > 0) {
        this.existingQuestionGroups = this.formatRFQQuestionGroupsForTemplates(this.rfq.questionGroups);
      }
      else {
        this.existingQuestionGroups = [];
      }
    },
    // So we can use teh RFQ format of question groups in templates seemlessly
    formatRFQQuestionGroupsForTemplates(questionGroups) {
      return questionGroups.map(group => {
        return {
          id: group.id,
          name: group.name,
          position: group.position,
          handlesLawyers: group.handles_lawyers || false,
          isShown: true,
          questionTemplatesAttributes: group.questions.map(question => {
            return {
              id: question.id,
              questionText: question.questionText,
              questionType: question.questionType,
              isCustomQuestion: question.isCustomQuestion,
              isIncluded: true,
              isRequired: question.isRequired,
              position: question.position,
              prerequisiteQuestionAnswer: question.prerequisiteQuestionAnswer || null,
              prerequisiteQuestionId: question.prerequisiteQuestionId || null,
              questionOptions: question.questionOptions || [],
              shortname: question.shortName || ''
            };
          })
        };
      });
    },
    formIsValid() {
      return this.hasSelectedTemplate();
    },
    onTemplateChange(selectedTemplate) {
      this.setRfqGroupTemplates(structuredClone(this.rfqTemplates.find((t) => t.name === selectedTemplate).questionGroupTemplatesAttributes));
      this.setSelectedTemplateByName(selectedTemplate);
      this.applyTemplate(selectedTemplate);
    },
    handleQuestionUpdate(updatedQuestionGroups) {
      // TODO: Handle updated questions
    },
    hasSelectedTemplate() {
      // Check if at least one question has isIncluded set to true
      return this.currentTemplate != '';
    },
    applyTemplate(templateName) {
      const selectedTemplate = this.rfqTemplates.find((t) => t.name === templateName);
      if (selectedTemplate) {
        this.rfqQuestionGroups = selectedTemplate.questionGroupTemplatesAttributes;
        this.setSelectedTemplateByName(templateName);
        this.currentTemplate = templateName;
        this.existingQuestionGroups = [];
      }
    },
    manageTemplates() {
      this.onSave({ rfq: this.rfq, step: 3 }).then(() => {
        window.location.href = '/scout-company/settings/question-templates';
      });
    },
    next() {
      this.nextStep.moveToNextStep();
    },
    previous() {
      this.prevStep.moveToPrevStep();
    }
  }
};
</script>

<style scoped lang="scss">
.blue-accent {
  background-color: #e6f0ff;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  position: relative;
}

.template-link {
  margin-top: 10px;
  display: inline;
  right: 16px;
  position: absolute;
  top: 5px;
  color: black;
  a {
    color: #333;
  }
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.question-group {
  margin-bottom: 20px;
}

.question-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
</style>
