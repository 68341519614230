<template>
  <div>
    <label>{{ label }}</label>

    <div class="a-form bottom-30">
      <label class="check-option vertical">
        <span class="normal-weight">{{ options[0].text }}</span>
        <input
          type="radio"
          :name="name"
          :value="options[0].value"
          v-model="selectedOption">
        <span class="check c-radio"></span>
      </label>

      <label class="check-option vertical">
        <span class="normal-weight">{{ options[1].text }}</span>
        <input
          type="radio"
          :name="name"
          :value="options[1].value"
          v-model="selectedOption">
        <span class="check c-radio"></span>
      </label>
    </div>

    <div v-if="selectedOption === options[0].value">
      <slot name="section-one"></slot>
    </div>

    <div v-else>
      <slot name="section-two"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioToggle',

  props: {
    label: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: true
    },

    options: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      selectedOption: this.options[0].value
    };
  }
};
</script>
